import React from 'react'
import Nav from './navbar-hp';
import { Outlet, Link, NavLink } from "react-router-dom";
import { useState, useEffect } from "react"

export default function Home5() {

    useEffect(() => {

        document.querySelector('a.app[href="/"] .app-wrap').classList.add('active');

    })

    return (
        <>

        <Nav />
        <div className="splash-bg"></div>
        <div className="splash">

            {/*div className="homepage-video-container">
                {/*<video autoplay="true" loop="true" muted="true" playsInline="true" src="images/ITATlrg.mp4"></video>*/}
                {/*<img src="images/Sequence 04.gif"/>
            </div>*/}

            <div className="splash-title">
                    <img id="splash-logo" className="" src="/images/Be Lucky Logo 5.png" alt="logo"/>
            </div>

            <div className="splash-content">
                
                <div className="splash-image">
                    <img id="splash-logo" className="" src="/images/beluckylogo-alt3.png" alt='logo'/>
                </div>

                <div className="homepage-socials spacing_bottom--med">
                    <div className="socialicons">
                        <Link className="socialiconlinks" to="https://open.spotify.com/artist/0COjQBYwITpwsJ7KVbQUbu" target="_blank">
                            <img src="images/social_logos_cropped/simple_icons/spotify.svg" alt="spotify logo"/>
                        </Link>
                        <Link className="socialiconlinks" to="https://music.apple.com/us/artist/be-lucky/1760904847" target="_blank">
                            <img src="images/social_logos_cropped/simple_icons/apple.svg" alt="apple music logo"/>
                        </Link>
                        <Link className="socialiconlinks" to="https://linktr.ee/beluckyband" target="_blank">
                            <img src="images/social_logos_cropped/simple_icons/linktree.svg" alt="link icon"/>
                        </Link>
                        <Link className="socialiconlinks" to="https://www.instagram.com/beluckyband/" target="_blank">
                            <img src="images/social_logos_cropped/simple_icons/ig2.svg" alt="instagram logo"/>
                        </Link>
                        <Link className="socialiconlinks" to="https://www.tiktok.com/@beluckyband" target="_blank">
                            <img src="images/social_logos_cropped/simple_icons/tt.svg" alt="tiktok logo"/>
                        </Link>
                        <Link className="socialiconlinks" to="https://www.youtube.com/channel/UCc3VCcdkHzRJtGqlepUFkCw" target="_blank">
                            <img src="images/social_logos_cropped/simple_icons/yt.svg" alt="youtube logo"/>
                        </Link>
                    </div>
                </div>

            </div>

        </div>

        </> 
    )
}