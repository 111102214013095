import React from 'react'
import { Outlet, Link, NavLink } from "react-router-dom";

function Apps() {
    
    return (
        <>
            
            <div className="apps-wrapper">
                <div className="apps-container">
                    <NavLink reloadDocument className="app" to='/'>
                        <div className="app-wrap">
                            <div className="app-icon"><img src="/images/home.png" alt="home icon"/></div>
                            <div className="app-name">HOME</div>
                        </div>
                    </NavLink>
                    <NavLink reloadDocument className="app" to='/tunage'>
                        <div className="app-wrap">
                            <div className="app-icon"><img src="/images/play.png" alt="play icon"/></div>
                            <div className="app-name">TUNES</div>
                        </div>
                    </NavLink>
                    {/*
                    <NavLink reloadDocument className="app disabled" to='/shows'>
                        <div className="app-wrap">
                            <div className="app-icon"><img src="/images/rnr.png" alt="rock and roll sign icon"/></div>
                            <div className="app-name">SHOWS</div>
                        </div>
                    </NavLink>
                    <NavLink reloadDocument className="app disabled" to='/news'>
                        <div className="app-wrap" >
                            <div className="app-icon"><img src="/images/newspaper.png" alt="newspaper icon"/></div>
                            <div className="app-name">NEWS</div>
                        </div>
                    </NavLink>*/}
                    <NavLink reloadDocument className="app" to='/contact'>
                        <div className="app-wrap">
                            <div className="app-icon"><img src="/images/mail.png" alt="mail icon"/></div>
                            <div className="app-name">CONTACT</div>
                        </div>
                    </NavLink>
                    {/*<NavLink reloadDocument className="app disabled" to='/about'>
                        <div className="app-wrap">
                            <div className="app-icon"><img src="/images/about.png" alt="information icon"/></div>
                            <div className="app-name">ABOUT</div>
                        </div>
                    </NavLink>*/}
                    <NavLink reloadDocument className="app" to='https://belucky777.bigcartel.com/' target="_blank">
                        <div className="app-wrap">
                            <div className="app-icon"><img src="/images/tshirt.png" alt="t-shirt icon"/></div>
                            <div className="app-name">MERCH</div>
                        </div>
                    </NavLink>
                    {/*<NavLink reloadDocument className="app disabled" to='/cpod'>
                        <div className="app-wrap">
                            <div className="app-icon"><img src="/images/ipod.png" alt="ipod icon"/></div>
                            <div className="app-name">cPod</div>
                        </div>
                    </NavLink>
                    <a className="app disabled">
                        <div className="app-wrap">
                            <div className="app-icon"><img src="/images/mail.png"/></div>
                            <div className="app-name">CONTACT</div>
                        </div>
                    </a>*/}

                </div>
            </div>

        </>
    )
}

export default Apps;